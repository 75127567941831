import { makeParametricUrl } from 'app-libs/etc';

import { K_JAPANDI } from 'constants/productConstants';

type Category = {
  imageUrl: string;
  linkUrl: string;
  title: string;
};

export const getCategoryLinkUrl = (
  cat: Category,
  shouldUseJapandiCategoryPage: boolean,
  areaSlug: string | null,
) => {
  if (shouldUseJapandiCategoryPage) {
    return `${cat.linkUrl}-${K_JAPANDI}`;
  }

  if (!areaSlug || cat.linkUrl.startsWith('/search')) {
    return cat.linkUrl;
  }
  return makeParametricUrl(`${cat.linkUrl}/:areaSlug`, { areaSlug });
};
