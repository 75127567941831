import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  sectionTitle: {
    ...cStyles.pbs,
  },

  container: {
    ...cStyles.mtxl,
  },

  categoryContainer: {
    marginHorizontal: 18,
  },

  marketplaceItemColumnSeparator: {
    ...cStyles.bwlm,
    borderColor: colors.C_BLACK_15,
  },

  marketplaceItemLastRow: {
    ...cStyles.bwbn,
  },
});
