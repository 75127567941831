import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';

export default StyleSheet.create({
  container: {
    ...cStyles.flexDirRow,
    ...cStyles.flex1,
    ...cStyles.mvxl,
    justifyContent: 'space-around',
  },
  itemContainer: {
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
  },
});
