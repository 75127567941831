import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import colors from '@dkrm/ui-kit-basic/colors';
import { tStyles } from '@dkrm/ui-kit-basic/typography';

export default StyleSheet.create({
  sectionContainer: {
    ...cStyles.phxl,
    marginTop: 40,
  },

  sectionSellerContainer: {
    ...cStyles.phxxl,
    marginTop: 20,
  },

  sectionContainerTop: {
    marginTop: 40,
  },

  sectionTitle: {
    ...tStyles.h2,
    color: colors.C_BLACK_100,
  },
  sectionTitleMarginLeft: {
    ...tStyles.h2,
    ...cStyles.mlxxl,
    color: colors.C_BLACK_100,
  },
  sectionSubtitleMarginLeft: {
    ...tStyles.body2,
    ...cStyles.mlxxl,
    color: colors.C_BLACK_90,
  },
});
