import loadable from '@loadable/component';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import React from 'react';

import withRouter from '@dkrm/general-libs/Utils/withRouter';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Colors, StyleSheet, Text, View } from '@dkrm/ui-kit-basic';

import ModalFallback from 'app-libs/components/ModalFallback';

import {
  K_MARKETPLACE_CATEGORIES_V3,
  K_USP_MARKETPLACE,
} from 'components/home/constants';
import CategoryCard from 'components/home/shared/components/CategoryCard';
import USP from 'components/home/shared/components/USP';

import sectionStyles from '../styles';
import styles from './styles';
import { getCategoryLinkUrl } from './utils';

const CategoryCardModal = loadable(() => import('./CategoryCardModal'));

const K_NUM_COLUMNS = 4;

@withRouter
export default class MarketplaceCategory extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
      }),
    ),
    numColumns: PropTypes.number,
    sectionTitle: PropTypes.string,
    sectionTitleStyle: stylePropTypes,
    containerStyle: stylePropTypes,
    shouldHideUSP: PropTypes.bool,
    shouldHideTitle: PropTypes.bool,
    shouldUseJapandiCategoryPage: PropTypes.bool,
    source: PropTypes.string,
    params: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    categories: null,
    numColumns: 3,
    sectionTitle: 'Furnitur & Perabot Terbaik',
    sectionTitleStyle: null,
    containerStyle: null,
    shouldHideUSP: false,
    shouldHideTitle: false,
    shouldUseJapandiCategoryPage: false,
    source: null,
  };

  state = {
    isModalOpened: false,
    childCategories: [],
  };

  handleCategoryCardPressed = (cat) => {
    const { childCategories } = cat;

    // Should open modal if have child categories
    if (childCategories) {
      this.setState({ isModalOpened: true, childCategories });
    }
  };

  closeModal = () => {
    this.setState({ isModalOpened: false });
  };

  renderCategoryItem = (cat, index, isLastRow) => {
    const {
      source,
      shouldUseJapandiCategoryPage,
      params: { areaSlug = null },
    } = this.props;
    const btnStyle = [
      index % K_NUM_COLUMNS && styles.marketplaceItemColumnSeparator,
      isLastRow && styles.marketplaceItemLastRow,
    ];

    const linkUrl = getCategoryLinkUrl(
      cat,
      shouldUseJapandiCategoryPage,
      areaSlug,
    );

    return (
      <CategoryCard
        onPress={this.handleCategoryCardPressed}
        key={cat.title}
        {...cat}
        linkUrl={linkUrl}
        titleStyle={s.categoryCardTitle}
        buttonStyle={btnStyle}
        source={source}
      />
    );
  };

  renderCategories = () => {
    const { categories } = this.props;
    const categoriesData = categories || K_MARKETPLACE_CATEGORIES_V3;
    const categoriesChunk = chunk(categoriesData, K_NUM_COLUMNS);
    const chunkLen = categoriesChunk.length;
    return (
      <React.Fragment>
        {categoriesChunk.map((categoriesChunkow, idxr) => (
          <View style={cStyles.flexDirRow} key={idxr}>
            {categoriesChunkow.map((cat, idx) =>
              this.renderCategoryItem(cat, idx, idxr === chunkLen - 1),
            )}
          </View>
        ))}
      </React.Fragment>
    );
  };

  render() {
    const {
      sectionTitle,
      sectionTitleStyle,
      containerStyle,
      shouldHideUSP,
      shouldHideTitle,
    } = this.props;
    const { isModalOpened, childCategories } = this.state;

    return (
      <>
        <View
          style={[
            sectionStyles.sectionContainer,
            styles.container,
            containerStyle,
          ]}
        >
          {!shouldHideTitle && (
            <Text
              style={[
                sectionStyles.sectionTitle,
                styles.sectionTitle,
                sectionTitleStyle,
              ]}
            >
              {sectionTitle}
            </Text>
          )}
          {!shouldHideUSP && (
            <USP usp={K_USP_MARKETPLACE} containerStyle={cStyles.mtl} />
          )}
          {this.renderCategories()}
        </View>
        {isModalOpened && (
          <CategoryCardModal
            isVisible={isModalOpened}
            actionCloseModal={this.closeModal}
            childCategories={childCategories}
            fallback={<ModalFallback closeModal={this.closeModal} />}
          />
        )}
      </>
    );
  }
}

const s = StyleSheet.create({
  categoryCardTitle: {
    ...cStyles.fwb,
    fontSize: 10,
    lineHeight: 12,
    color: Colors.C_BLACK_100,
  },
});
