import config from 'config';

import {
  FreeFurniture,
  FreeShipping,
  Installment,
  Interior,
  MoneyBackGuarantee,
  People,
  PeopleConsult,
  PriceHouse,
} from '@dkrm/icons';

import {
  K_PORTFOLIO_STYLE_JAPANDI_NATURAL_PATHNAME,
  K_PORTFOLIO_STYLE_MODERN_CLASSIC_PATHNAME,
  K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_PATHNAME,
  K_ROUTE_BUSINESS,
  K_ROUTE_KITCHEN_SET,
  K_ROUTE_PORTFOLIO_LIST,
  K_ROUTE_PORTFOLIO_LIST_HOUSE,
  K_ROUTE_PORTOFOLIO_LIST_APARTMENT,
  K_ROUTE_WALK_IN_CLOSET,
} from 'constants/routeConstants';

export const K_MARKETPLACE_CATEGORIES = [
  {
    title: 'Furniture',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/furnitur.png?auto=webp`,
    linkUrl: '/Furniture',
  },
  {
    title: 'Kasur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/kasur.png?auto=webp`,
    linkUrl: '/kasur',
  },
  {
    title: 'Sprei',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/sprei.png?auto=webp`,
    linkUrl: '/Sprei',
  },
  {
    title: 'Perlengkapan\nKamar Tidur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/bed.png?auto=webp`,
    linkUrl: '/Perlengkapan-Kamar-Tidur',
  },
  {
    title: 'Peralatan Dapur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/peralatandapur.png?auto=webp`,
    linkUrl: '/Peralatan-Dapur',
  },
  {
    title: 'Alat Makan\n& Minum',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/perlengkapanmakanminum.png?auto=webp`,
    linkUrl: '/Peralatan-Makan-&-Minum',
  },
  {
    title: 'Dekorasi',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/dekorasi.png?auto=webp`,
    linkUrl: '/Dekorasi',
  },
  {
    title: 'Perabotan',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/perabotan.png?auto=webp`,
    linkUrl: '/Perkakas-Rumah-Tangga',
  },
  {
    title: 'Lampu',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/lampu.png?auto=webp`,
    linkUrl: '/Lampu',
  },
  {
    title: 'Perlengkapan\nKamar Mandi',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/perlengkapankamarmandi.png?auto=webp`,
    linkUrl: '/Aksesoris-Kamar-Mandi',
  },
  {
    title: 'Home\nImprovement',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/homeimprovement.png?auto=webp`,
    linkUrl: '/Home-Improvement',
  },
  {
    title: 'Sale',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/sale.png?auto=webp`,
    linkUrl: '/sale',
  },
];

export const K_MARKETPLACE_CATEGORIES_V3 = [
  {
    title: 'Sofa &\nSofabed',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/SofaSofabed.png?auto=webp`,
    linkUrl: '/Sofa',
  },
  {
    title: 'Tekstil: Cushion, Karpet',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/Tekstil.png?auto=webp`,
    linkUrl: '/Tekstil',
  },
  {
    title: 'Meja TV & Credenza',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/MejaTV.png?auto=webp`,
    linkUrl: '/Meja-TV',
    childCategories: [
      {
        title: 'Meja TV',
        imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/MejaTV.png?auto=webp`,
        linkUrl: '/Meja-TV',
      },
      {
        title: 'Credenza',
        imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/KabinetRakPenyimpanan.png?auto=webp`,
        linkUrl: '/Credenza',
      },
    ],
  },
  {
    title: 'Meja Tamu & Samping',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/CoffeeTable.png?auto=webp`,
    linkUrl: '/Meja-Tamu-&-Nakas',
  },
  {
    title: 'Meja Kerja',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/MejaKerja.png?auto=webp`,
    linkUrl: '/Meja-Kerja',
  },
  {
    title: 'Kursi Kantor',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/KursiKantor.png?auto=webp`,
    linkUrl: '/Kursi-Kantor',
  },
  {
    title: 'Rak & Penyimpanan',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/RakBuku.png?auto=webp`,
    linkUrl: '/Rak-&-Penyimpanan',
  },
  {
    title: 'Lemari Laci',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/LemariLaci.png?auto=webp`,
    linkUrl: '/Lemari-Laci',
  },
  {
    title: 'Kasur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/Kasur.png?auto=webp`,
    linkUrl: '/kasur',
  },
  {
    title: 'Sprei &\nSelimut',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/PerlengkapanKamarTidur.png?auto=webp`,
    linkUrl: '/Sprei',
  },
  {
    title: 'Lampu',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/Lampu.png?auto=webp`,
    linkUrl: '/Lampu',
  },
  {
    title: 'Cermin',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/Cermin.png?auto=webp`,
    linkUrl: '/Cermin',
  },
  {
    title: 'Tempat Tidur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/TempatTidur.png?auto=webp`,
    linkUrl: '/Tempat-Tidur',
  },
  {
    title: 'Lemari Pakaian',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/LemariPakaian.png?auto=webp`,
    linkUrl: '/Lemari-Pakaian',
  },
  {
    title: 'Tempat\nPenyimpanan',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/TempatPenyimpanan.png?auto=webp`,
    linkUrl: '/Tempat-Penyimpanan',
  },
  {
    title: 'Dekorasi & Tanaman Hias',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/DekorasiTanamanHias.png?auto=webp`,
    linkUrl: '/Dekorasi',
    childCategories: [
      {
        title: 'Dekorasi',
        imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/Dekorasi.png?auto=webp`,
        linkUrl: '/Dekorasi',
      },
      {
        title: 'Tanaman Hias & Vas',
        imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/TanamanHiasVas.png?auto=webp`,
        linkUrl: '/Tanaman-Hias-&-Vas',
      },
    ],
  },
  {
    title: 'Meja & Kursi\nMakan',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/MejaKursiMakan.png?auto=webp`,
    linkUrl: '/Meja-&-Kursi-Makan',
  },
  {
    title: 'Peralatan\nMakan/Minum',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/PeralatanMakanMinum.png?auto=webp`,
    linkUrl: '/Peralatan-Makan-&-Minum',
  },
  {
    title: 'Kabinet Dapur & Troli',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/KabinetDapur.png?auto=webp`,
    linkUrl: '/Kabinet-Dapur-&-Troli',
  },
  {
    title: 'Peralatan\nDapur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v6/PeralatanDapur.png?auto=webp`,
    linkUrl: '/Peralatan-Dapur',
  },
];

export const K_MARKETPLACE_LVL0_CATEGORIES = [
  { name: 'Furniture', value: 'Furniture' },
  { name: 'Kasur', value: 'Kasur' },
  { name: 'Perlengkapan Kamar Tidur', value: 'Perlengkapan Kamar Tidur' },
  { name: 'Peralatan Dapur', value: 'Peralatan Dapur' },
  { name: 'Alat Makan & Minum', value: 'Perlengkapan Makan & Minum' },
  { name: 'Dekorasi', value: 'Dekor' },
  { name: 'Perabotan', value: 'Peralatan Rumah Tangga' },
  { name: 'Lampu', value: 'Lampu' },
  { name: 'Perlengkapan Kamar Mandi', value: 'Perlengkapan Kamar Mandi' },
  { name: 'Home Improvement', value: 'Home Improvement' },
];

export const K_UPSELL_BUSINESS_UNITS = [
  {
    title: 'Interior &\nKitchen Set',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/kitchen.png?auto=webp`,
    linkUrl: '/interior',
  },
  {
    title: 'Beli\nHunian',
    imageUrl: `${config.API_URL_MEDIA_CDN}/facet/category/v3/apartment.png?auto=webp`,
    linkUrl: '/properti',
    buttonProps: { linkProps: { isUsingAnchor: true } },
  },
];

export const K_DESIGN_AND_BUILD_HERO = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v3/dekohome-main-banner.jpg?auto=webp`;

export const K_DESIGN_AND_BUILD_SERVICE_TYPES_V2 = [
  {
    name: 'Interior Rumah',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v5/interior-rumah.png?auto=webp`,
    linkUrl: '/proyek/Desain-Interior-Rumah',
    linkProps: { isUsingAnchor: true },
  },
  {
    name: 'Dapur',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v5/dapur.png?auto=webp`,
    linkUrl: '/promosi/dapur-dama',
  },
  {
    name: 'Interior Apartemen',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v5/interior-apartemen.png?auto=webp`,
    linkUrl: '/proyek/Desain-Interior-Apartemen',
    linkProps: { isUsingAnchor: true },
  },
  {
    name: 'Interior Kantor & Hotel',
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/desain-dan-pembuatan/v5/interior-kantor-hotel.png?auto=webp`,
    linkUrl: '/business',
    linkProps: { isUsingAnchor: true },
  },
];

export const K_INTERIOR_STYLE_CATEGORIES = [
  {
    name: 'Modern Kontemporer',
    imageUrl: `${config.API_URL_MEDIA_CDN}/design-and-build/portfolio/style/Modern Kontemporer.jpg`,
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}${K_PORTFOLIO_STYLE_MODERN_CONTEMPORARY_PATHNAME}`,
  },
  {
    name: 'Modern Klasik',
    imageUrl: `${config.API_URL_MEDIA_CDN}/design-and-build/portfolio/style/Modern Klasik.jpg`,
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}${K_PORTFOLIO_STYLE_MODERN_CLASSIC_PATHNAME}`,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/design-and-build/portfolio/style/Japandi Natural.jpg`,
    name: 'Japandi Natural',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}${K_PORTFOLIO_STYLE_JAPANDI_NATURAL_PATHNAME}`,
  },
  {
    name: 'Lihat\nSemua Gaya',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}`,
    isFillerData: true,
  },
];

export const K_INTERIOR_ROOM_CATEGORIES = [
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/KITCHEN.jpg`,
    name: 'Kitchen Set',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}${K_ROUTE_KITCHEN_SET}`,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/RUMAH.jpg`,
    name: 'Rumah',
    linkUrl: K_ROUTE_PORTFOLIO_LIST_HOUSE,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/APARTMENT.jpg`,
    name: 'Apartemen',
    linkUrl: K_ROUTE_PORTOFOLIO_LIST_APARTMENT,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/WARDROBE.jpg`,
    name: 'Lemari Custom',
    linkUrl: `${K_ROUTE_PORTFOLIO_LIST}${K_ROUTE_WALK_IN_CLOSET}`,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/KANTOR.jpg`,
    name: 'Kantor',
    linkUrl: K_ROUTE_BUSINESS,
    isUsingAnchor: true,
  },
  {
    imageUrl: `${config.API_URL_MEDIA_CDN}/homepage/interior/product offerings/HOTEL.jpg`,
    name: 'Hotel',
    linkUrl: K_ROUTE_BUSINESS,
    isUsingAnchor: true,
  },
];

export const K_HOUSE_HERO = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/properti/dekohouse-main-banner.png?auto=webp`;
export const K_HOUSE_SELLER_BANNER = `${config.API_URL_MEDIA_CDN}/homepage/mobile/banner/properti/dekohouse-seller-landing-page-entry-point.png?auto=webp`;

export const K_USP_MARKETPLACE = [
  {
    text: 'Gratis\nOngkir*',
    IconComponent: FreeShipping,
  },
  {
    text: 'Cicilan 0%\nHingga 12x',
    IconComponent: Installment,
  },
  {
    text: 'Retur\n14 Hari',
    IconComponent: MoneyBackGuarantee,
  },
];

export const K_USP_DNB = [
  {
    text: 'Cicilan 0%*',
    IconComponent: Installment,
  },
  {
    text: '8 Minggu\nSelesai*',
    IconComponent: Interior,
  },
  {
    text: '100+ Desainer\nTerakreditasi',
    IconComponent: People,
  },
];

export const K_USP_HOUSE = [
  {
    text: 'Bebas Pilih\nFurnitur',
    IconComponent: FreeFurniture,
  },
  {
    text: 'Gratis\nKonsultasi',
    IconComponent: PeopleConsult,
  },
  {
    text: 'Harga\nTerbaik',
    IconComponent: PriceHouse,
  },
];
