import React from 'react';
import PropTypes from 'prop-types';

import { Text, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button } from '@dkrm/ui-kit-basic/v2';

import { getPathFromUrl } from 'app-libs/etc/routeHelper';

import { Image as SmartImage } from '@dkrm/ui-kit-basic/v3';

import { trackClickMarketplaceCategory } from 'app-libs/trackers';
import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import styles from './styles';

const K_IMAGE_SIZE = 68;

export default class CategoryCard extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    childCategories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        linkUrl: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
      }),
    ),
    buttonProps: PropTypes.shape(),
    onPress: PropTypes.func,
    imageProps: PropTypes.shape(),

    containerStyle: stylePropTypes,
    buttonStyle: stylePropTypes,
    buttonContainerStyle: stylePropTypes,
    titleStyle: stylePropTypes,
    titleContainerStyle: stylePropTypes,
    imageStyle: stylePropTypes,
    source: PropTypes.string,
  };

  static defaultProps = {
    childCategories: null,
    buttonProps: {},
    onPress: () => null,
    imageProps: {},
    containerStyle: null,
    buttonStyle: null,
    buttonContainerStyle: null,
    titleStyle: null,
    titleContainerStyle: null,
    imageStyle: null,
    source: null,
  };

  renderImage() {
    const { title, imageUrl, imageProps, imageStyle } = this.props;

    return (
      <SmartImage
        source={imageUrl}
        resizeMode="contain"
        style={imageStyle}
        width={K_IMAGE_SIZE}
        height={K_IMAGE_SIZE}
        accessibilityLabel={title}
        {...imageProps}
      />
    );
  }

  handlePress = () => {
    const { childCategories, onPress, linkUrl, title, source } = this.props;
    onPress({ childCategories, to: linkUrl, name: title });
    trackClickMarketplaceCategory(linkUrl, title, source);
  };

  render() {
    const {
      buttonProps,
      buttonStyle,
      buttonContainerStyle,
      title,
      titleStyle,
      titleContainerStyle,
      childCategories,
      linkUrl,
    } = this.props;

    const to = childCategories ? undefined : getPathFromUrl(linkUrl);

    if (!title) return <View style={[styles.button, buttonStyle]} />;

    return (
      <View style={[styles.button, buttonStyle]}>
        <Button
          hoverableViewStyle={[cStyles.flex1, cStyles.height100]}
          style={cStyles.flex1}
          onPress={this.handlePress}
          to={to}
          {...buttonProps}
        >
          {() => (
            <View style={[styles.buttonContainer, buttonContainerStyle]}>
              {this.renderImage()}
              <View style={[styles.titleContainer, titleContainerStyle]}>
                <Text style={[styles.title, titleStyle]}>{title}</Text>
              </View>
            </View>
          )}
        </Button>
      </View>
    );
  }
}
