import PropTypes from 'prop-types';
import React from 'react';

import cStyles, { stylePropTypes } from '@dkrm/general-libs/theme/styles';
import { Text, View } from '@dkrm/ui-kit-basic';

import styles from './styles';

export default class USP extends React.PureComponent {
  static propTypes = {
    usp: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        imageUrl: PropTypes.string,
      }),
    ),
    containerStyle: stylePropTypes,
    iconSize: PropTypes.number,
  };

  static defaultProps = {
    usp: [],
    containerStyle: null,
    iconSize: 24,
  };

  render() {
    const { usp, containerStyle, iconSize } = this.props;
    if (!usp.length) return null;

    return (
      <View style={[styles.container, containerStyle]}>
        {usp.map(({ IconComponent, text }) => (
          <View style={styles.itemContainer}>
            <IconComponent theme="black" size={iconSize} />
            <Text theme="body4-black" style={cStyles.mlm}>
              {text}
            </Text>
          </View>
        ))}
      </View>
    );
  }
}
