import { StyleSheet } from 'react-native';

import cStyles from '@dkrm/general-libs/theme/styles';
import { tStyles } from '@dkrm/ui-kit-basic/typography';
import colors from '@dkrm/ui-kit-basic/colors';

export default StyleSheet.create({
  button: {
    ...cStyles.flex1,
    ...cStyles.flexDirRow,
    ...cStyles.flexMiddle,
    ...cStyles.pts,
    ...cStyles.bwbm,
    borderColor: colors.C_BLACK_15,
    alignItems: 'stretch',
  },

  buttonContainer: {
    ...cStyles.pam,
    ...cStyles.ptl,
    ...cStyles.brs,
    ...cStyles.pbn,
    ...cStyles.flex1,
    alignItems: 'center',
    paddingTop: 4,
  },

  titleContainer: {
    ...cStyles.flex1,
    ...cStyles.mts,
    ...cStyles.mbs,
    ...cStyles.flexMiddle,
  },
  title: {
    ...tStyles.body4,
    color: colors.C_SECONDARY_TOSCA_5,
    textAlign: 'center',
  },
});
